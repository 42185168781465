.af-view {
  .export-tools-container {
    max-width: 1100px;
  }

  .admin-export-tools-container {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 30px 30px 20px;
    background-color: #fff;

    fieldset {
      border: 0 none;
    }

    legend {
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
    }

    label {
      display: inline;
    }

    input[type="radio"] {
      margin: 0px 5px 0px 15px;
    }

    .radio-button-container {
      margin-bottom: 5px;
    }

    .date-picker-container {
      display: flex;
      flex-direction: row;
      max-width: 500px;
      margin: 10px 5px;

      .date-picker-section {
        flex: 1 1 auto;
        margin: 0px 5px;

        input[type="text"] {
          display: block;
          margin-bottom: 5px;
        }
      }

      .date-validation-msg {
        min-height: 20px;

        &.is-invalid {
          font-size: 90%;
          color: red;
        }
      }
    }

    .download-link-container {
      padding: 16px 0 0 0;
    }

    .export-data-list {
      margin: 20px 0;

      thead,
      tbody {
        tr {
          border-bottom: 1px solid rgb(192, 192, 192);
        }
      }

      tbody {
        tr:last-child {
          border: 0;
        }
      }

      th, td {
        padding: 5px 50px 5px 0;
        text-align: left;
      }

      .status {
        min-width: 150px;

        &.status-completed {
          font-weight: bold;
        }

        &.status-failed {
          color: red;
        }
      }
    }
  }

  .ld-spin {
    vertical-align: middle;
  }
}
