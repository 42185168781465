body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

%dark-link {
  color: #333;
}

.af-view {
  .af-class-breadcrumb-wrapper {
    a {
      @extend %dark-link;
    }
  }

  a.af-class-paragraph-small-link {
    @extend %dark-link;
  }

  a.af-class-button-medium {
    color: white;
    font-weight: 600;
  }

  .af-class-tabs {
    margin-top: 0;
  }
}

@media print {
    body * {
        font-weight: normal !important;
    }
}
