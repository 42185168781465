#input-hero-frontpage {
  .search-input {
    position: absolute;
    left: 0;
    top: 0;
    padding-left: 20px;
    padding-right: 0;
    background: transparent;
    border: none;
    border-color: transparent;
    width: 100%;
    height: 100%;
    margin: 0;
    color: rgba(59,57,77,.9);
    &:focus {
      outline-width: 0;
    }
    display: inline;

    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgba(59,57,77,.9);
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: rgba(59,57,77,.9);
    }

    &::-ms-input-placeholder { /* Microsoft Edge */
      color: rgba(59,57,77,.9);
    }
  }
  .w-button {
    &:before {
      display: block;
      width: 0;
      content: " ";
      position: absolute;
      left: -5px;
      height: 100%;
      top: 0;
      border-left: 5px solid white;
      z-index: 1;
      margin-left: 0;
    }
    &:after {
      display: block;
      width: 0;
      content: " ";
      position: absolute;
      right: -2px;
      height: 100%;
      top: 0;
      border-left: 2px solid white;
      z-index: 1;
      margin-left: 0;
    }
  }
}
