.active {
  background-color: grey;
}
.details {
  display: flex;
  padding: 5px;
  border: 2px solid black;
  flex-wrap: wrap;
  > section {
    width: 50%;
  }
}
section.stats {
  span {
    border: 1px solid red;
    padding: 10px;
  }
}
.af-view {
  .error-input {
    border-color: #e52121;
  }
  .w-tab-menu {
    .w-tab-link.active {
      border: 1px solid black;
    }
  }
  .af-class-button-admin-notes {
    margin-top: 10px;
  }
}
