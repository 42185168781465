.af-view {
  .error-input {
    border-color: #e52121;
  }

  .af-class-div-block-offer-request-multiselect-button {
    user-select: none;
  }

  .af-class-multiselect-invalid {
    border-color: #e52121;
  }

  .not-selling-last-section {
    .af-class-h4-lift-offer-request,
    .af-class-button,
    .af-class-div-block-offer-request-multiselect-button {
      display: none !important;
    }
  }

  .af-commission-receipt-link {
    margin-top: 0.5rem;
  }
}
