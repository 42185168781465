.af-offer-request-not-available-wrapper {
  width: 90%;
  max-width: 900px;
  margin: 20px auto 20px auto;
  padding: 30px 30px 20px;
  background-color: #fff;

  .af-offer-request-not-available-header {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    text-align: left;
  }

  @media (min-width: 992px) {
    min-height: 500px;
  }
}
