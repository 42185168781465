.af-class-custom-status-selector {
   clear: both;
   margin: 0.5rem 0;
}

.af-admin-tools {
   display: flex;
   flex-direction: row;
   margin-bottom: 10px;

   .af-class-custom-status-filtering {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      overflow: auto;
      clear: both;
      margin: 0 0 1rem 0;

      select {
         float: right;
         padding: 0.25rem;
         font-size: 16px;
      }
   }

   .af-class-text-search {
      display: flex;
      flex: 1;
      justify-content: flex-start;
      position: relative;

      &::after {
         content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"/></svg>');
         position: absolute;
         display: block;
         top: 11px;
         left: 11px;
         width: 16px;
         height: 16px;
         opacity: 0.25;
      }

      .text-search {
         width: 75%;
         max-width: 500px;
         padding-left: 34px;
      }

      .clear-text-search-btn {
         display: none;
         appearance: none;
         flex: 1;
         height: 38px;
         background: transparent;

         .clear-text-search-icon {
            content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>');
            display: inline-block;
            width: 24px;
            height: 24px;
            margin-top: 6px;
         }
      }
   }
}

.af-class-data-row {
   &.custom-status-fulfilled {
      .af-class-feed-wrapper-offer-info-table,
      .af-class-action-wrapper,
      .af-class-div-block-admin-info-text,
      .af-class-div-block-notes-form {
         background-color: rgb(238, 250, 234);
      }
   }

   &.custom-status-not-fulfilled {
      .af-class-feed-wrapper-offer-info-table,
      .af-class-action-wrapper,
      .af-class-div-block-admin-info-text,
      .af-class-div-block-notes-form {
         background-color: rgb(236, 250, 254);
      }
   }
}

.af-view {
   .reclamation-button {
      display: inline-block;
      min-height: 20px;
      margin: 0px 5px;
      padding: 3px 6px;
      border-radius: 4px;
      background-color: red;
      font-weight: bold;
      line-height: 0;
      color: white;
   }

   .link-separator {
      padding: 0 5px;
   }
}

.af-class-admin-table-info-text {
   &.row-rejected {
      background-color: rgb(221, 255, 232);

      &.has-contacted-at {
         background-color: rgb(255, 241, 221);
      }
   }
}

.is-text-search-active {
   .af-class-tabs {
      display: none;
   }

   .af-admin-tools {
      .af-class-text-search {
         .clear-text-search-btn {
            display: flex;
         }
      }
   }
}
